@import 'node_modules/@jpmuitk/style/scss/jpmuitk-style-vars.scss';

.web-id-select {
  padding-bottom: 2em;

  .web-id-selection-border {
    box-sizing: border-box;
    margin: auto;
    border: 1px solid $light-grey20;
  }

  .ag-status-bar {
    padding: 0px !important;
    border: none;
  }

  h2,
  p {
    text-align: center;
  }
}
