@import 'node_modules/@jpmuitk/style/scss/jpmuitk-style-vars.scss';

.stopgo-grid {
  .ag-header-row,
  .ag-body-container {
    width: 100% !important;
  }
  .ag-header-cell[col-id='uploadDate'],
  .uploadDate-cls {
    width: 15% !important;
    min-width: 100px;
  }
  .ag-header-cell[col-id='originalFileName'],
  .originalFileName-cls {
    left: 15% !important;
    width: 15% !important;
    min-width: 100px;
  }
  .ag-header-cell[col-id='program'],
  .program-cls {
    left: 30% !important;
    width: 25% !important;
    min-width: 100px;
  }
  .ag-header-cell[col-id='actionType'],
  .actionType-cls {
    left: 55% !important;
    width: 10% !important;
    min-width: 100px;
  }
  .ag-header-cell[col-id='effectiveDate'],
  .effectiveDate-cls {
    left: 65% !important;
    width: 10% !important;
    min-width: 40px;
  }
  .ag-header-cell[col-id='fileStatus'],
  .fileStatus-cls {
    left: 75% !important;
    width: 10% !important;
    min-width: 30px;
  }
  .ag-header-cell[col-id='errorMessage'],
  .errorMessage-cls {
    left: 85% !important;
    width: 20% !important;
    text-align: left;
    min-width: 80px;
  }
}
.UploadDialog {
  &-Content {
    padding: 24px !important;
  }
}
#contentStatus {
  width: 100%;
  box-sizing: border-box;
}
