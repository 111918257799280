@import '~utility/mixins';
@import 'node_modules/@jpmuitk/style/scss/jpmuitk-style-vars.scss';
@import '~Constants/variables.module';

body > div#V4LLPanel_Container,
body div#LL_sessionEnded_popup {
  display: none !important;
}

/* ag-grid Checkbox */
.ag-right-aligned-header .ag-header-cell-label {
  flex-direction: row;
}
.ag-header-cell[col-id='checkbox'] {
  color: #000;
  .ag-cell-label-container {
    @include visually-hidden;
  }
}
/* ag-grid status bar */
.ag-status-bar {
  border: 0;
}

td.CalendarDay__blocked_out_of_range {
  color: #cacccd !important;
  cursor: default !important;
  &:hover {
    background: $light-white !important;
  }
}

td.CalendarDay__selected {
  background: $light-grey200 !important;
  color: $light-white !important;
  border: none !important;
}

.toast-uitk {
  width: 248px;
  & .Toastify__toast {
    padding: 0;
  }
}

body {
  margin: 0;
}
body > div[x-placement] {
  z-index: 9999;
}
html {
  overflow: hidden;
}
#root {
  .content {
    flex-grow: 1;
    display: flex;
    overflow: auto;
  }

  .utilityNav {
    width: 100%;
  }

  .menu {
    box-shadow: none;
  }

  label[class*='FormField-labelArea'] {
    top: 0 !important;
  }
}

.error-list {
  list-style: none;
  padding: 0px;
}

.visually-hidden {
  @include visually-hidden;
}

.global-error-message {
  color: $light-red500;
  font-weight: bold;

  span {
    margin-right: 5px;
    color: $light-red500;
  }
}

p {
  margin: $margin-24 0;
}

/* Overrides */
.SplitPane {
  height: auto !important;
  height: initial !important;
}
.ag-body-container {
  margin-bottom: 0 !important;
}
/* Progress bar */
.content {
  .rc-slider-mark-text {
    pointer-events: none;
  }
  .rc-slider {
    & > .rc-slider-handle,
    & > .rc-slider-step {
      cursor: default;
      &::before,
      &::after {
        cursor: default;
      }
    }
  }
}

::placeholder {
  opacity: 1 !important;
  color: $light-grey700;
}

div[ro-style-override='ui-toolkit-dropdown'] {
  &,
  button {
    opacity: 1 !important;
  }
}
