@import 'node_modules/@jpmuitk/style/scss/jpmuitk-style-vars.scss';
@import '~Constants/variables.module';

.export-dialog-radio-btn > label {
  margin-top: 0;
}

.ExportDialog-Spinner {
  margin: $margin-24 auto;
}
